<template>
  <div class="container is-max-desktop">
    <h3 class="is-size-1">Nous contacter</h3>
    <form @submit.prevent="sendMail(form)">
    <section class="ml-6 mr-6">
      <b-field label="Nom">
        <b-input v-model="form.name" placeholder="Votre nom" required></b-input>
      </b-field>

      <b-field label="Adresse mail" required>
        <b-input type="email" placeholder="Votre adresse mail" maxlength="50" v-model="form.email" :has-counter="false" required>
        </b-input>
      </b-field>

      <b-field label="Sujet">
        <b-select placeholder="Sélectionnez un sujet" v-model="form.subject" required>
          <option value="Question générale">Question générale</option>
          <option value="Annuler une commande">Annuler une commande</option>
          <option value="Annuler une inscription">Annuler une inscription</option>
        </b-select>
      </b-field>

      <b-field label="Message">
        <b-input maxlength="2000" placeholder="Votre message" type="textarea" v-model="form.message" :has-counter="false" required></b-input>
      </b-field>
    </section>
      <br>
      <footer>
        <b-button
          label="Envoyer"
          native-type="submit"
          type="is-success submit"/>
      </footer>
    </form>
  </div>
</template>

<script>
import notification from "@/mixins/notification";

export default {
  data: () => {
    return {
      form: {},
    }
  },
  mixins: [notification],
  methods: {
    sendMail: function (infos) {
      if (infos.email.indexOf('@') > -1 && infos.email.indexOf('.') > -1 && infos.subject != null && infos.name != null && infos.message != null) {
        this.$store.dispatch('sendMail', infos).then(() => {
          this.showNotification('Message envoyé.', 'success');
          this.$router.push({ name: 'Home'})
        })
          .catch((error) => {
            console.log(error)
            this.showError('L\'envoi n\'a pas pu être effectué.')
          })
      }
    },

  }
}
</script>
